<template>
  <main>
    <!-- Use Leaflet? -->
    <!-- <iframe
      id="map"
      frameborder="0"
      scrolling="no"
      src="https://www.openstreetmap.org/export/embed.html?bbox=9.57810401916504%2C54.84424861795506%2C9.728307723999023%2C54.89586094087869&amp;layer=mapnik&amp;marker=54.87006303928002%2C9.653205871582031"
    ></iframe> -->
    <section id="intro">
      <div class="c">
        <h2>Brunch</h2>
        <h1>hos Iller Slot</h1>
      </div>
    </section>
    <section id="sti">
      <article class="c">
        <img src="../../assets/img/583.jpg" alt="Billede taget fra Gendarmstien nær det nærtliggende teglværk." width="583" height="364"/>
        <div>
          <h1>{{ $t('info.gendarmstien.title') }}</h1>
          <p>{{ $t('info.gendarmstien.texts[0]') }}</p>
          <p>{{ $t('info.gendarmstien.texts[1]') }}</p>
          <p>
            {{ $t('info.gendarmstien.texts[2]') }}
            <a
              :href="$t('info.gendarmstien.texts[3]')"
            >{{ $t('info.gendarmstien.texts[3]') }}</a>
          </p>
        </div>
      </article>
    </section>
    <div class="c">
      <hr />
    </div>
    <section id="leje">
      <article class="c">
        <div>
          <h1>{{ $t('info.winter.title') }}</h1>
          <p>{{ $t('info.winter.texts[0]') }}</p>
          <p>{{ $t('info.winter.texts[1]') }}</p>
          <p>{{ $t('info.winter.texts[2]') }}</p>
          <p>
            {{ $t('info.winter.texts[3]') }}
            <a
              href="mailto:kontakt@illerslot.dk"
            >kontakt@illerslot.dk</a>.
          </p>
        </div>
        <img src="../../assets/img/vint.jpg" alt="Billede at Iller Slot's hovedindgang, taget om aftenen." width="583" height="364"/>
      </article>
    </section>
    <div class="c">
      <hr />
    </div>
    <section id="historie">
      <article class="c">
        <h1>{{ $t('info.story.title') }}</h1>
        <div>
          <div v-for="info in $t('info.story.texts')" :key="info.year">
            <h2>{{ info.year }}</h2>
            <p v-for="text in info.texts" :key="text">{{ text }}</p>
          </div>
        </div>
      </article>
    </section>
  </main>
</template>

<style scoped>
hr {
  border-top: 0;
  border-bottom: 1px solid #ccc;
}

#map {
  display: block;
  width: 1600px;
  max-width: 100%;
  margin: 0 auto;
  height: calc(40vh);
  max-height: 550px;
}

#sti > article {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 50px 30px;
  align-items: flex-start;
}

#sti > article > * {
  width: calc(50% - 12px);
}

#sti > article > div > p {
  line-height: 1.5;
  margin-top: 4px;
}

#sti > article > div > p:not(:first-of-type) {
  margin-top: 12px;
}

#leje {
  width: 100%;
}

#leje > article {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 50px 30px;
}

#leje > article > * {
  width: calc(50% - 12px);
}

#leje > article > div > p {
  line-height: 1.5;
  margin-top: 4px;
}

#leje > article > div > p:not(:first-of-type) {
  margin-top: 12px;
}

#historie > article {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
}

#historie > article > h1 {
  padding-bottom: 4px;
  /*margin-left: 8px;*/
  text-align: center;
}

#historie > article > div {
  max-width: 600px;
  margin: 0 auto;
}

#historie > article > div > div {
  margin-top: 40px;
}

#historie > article > div > div > p {
  line-height: 1.5;
  font-size: 18px;
  margin-top: 12px;
}

@media (max-width: 1000px) {
  #sti > article {
    flex-direction: column;
  }

  #sti > article > * {
    width: 100%;
    max-width: 583px;
    margin: 0 auto;
  }

  #sti > article > *:nth-child(2) {
    margin-top: 10px;
  }

  #leje > article {
    flex-direction: column-reverse;
  }

  #leje > article > * {
    width: 100%;
    max-width: 583px;
    margin: 0 auto;
  }

  #leje > article > *:nth-child(1) {
    margin-top: 10px;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 2.2rem;
  }
}

/*@import "../assets/lib/leaflet/leaflet.css";*/
</style>

<script>
export default {
  mounted() {
    //const leaflet = document.createElement("script");
    //leaflet.setAttribute("src", "../assets/lib/leaflet/leaflet.js");
    //document.head.appendChild(leaflet);
    //var mymap = L.map("map").setView([51.505, -0.09], 13);
  },
};
</script>